import { AuthService } from '@digitalworkflow/dwloginclient'
import { LocalSettings } from '../utils/LocalSettings'
import { EnvironmentTypes } from '../types/types'
import { loginDevUrl, loginProdUrl, loginStagingUrl } from './urls'
import { MenuItem, ProfileMenuItem } from '@digitalworkflow/dwreactcommon'

export const authServiceInstance = AuthService.instance()

export const getEnv = (): EnvironmentTypes => {
  const _env = (process.env.REACT_APP_ENV || '').toLowerCase().trim()

  if (_env === 'stg') return EnvironmentTypes.STAGING
  else if (_env === 'prod') return EnvironmentTypes.PRODUCTION
  else if (_env === 'dev') return EnvironmentTypes.DEV
  else return EnvironmentTypes.DEV
}

export const getLoginPortalHost = () => {
  const _env = getEnv()

  if (_env === EnvironmentTypes.STAGING) return loginStagingUrl
  else if (_env === EnvironmentTypes.PRODUCTION) return loginProdUrl
  else return loginDevUrl
}

export const projectName = 'dwairportportal'

export const getProjectEnvironment = () => {
  return `${projectName}-${getEnv()}`
}
export const clearLoginData = () => {
  authServiceInstance.authLogout()
  LocalSettings.removePortalAuthToken()
  LocalSettings.removePortalUser()
}

export const redirectToLogin = () => {
  clearLoginData()

  console.log('Redirecting to Login Portal')
  window.location.href = `${getLoginPortalHost()}/login/${getLoginPortalID()}?return=` + window.location.hostname
}

export const getLoginPortalID = () => {
  return 'gWWmNxzt'
  // return 'NdE2viPW'
}

/**
 * Array of menu items to be displayed in the profile dropdown.
 */
export const profileMenuItems: Array<ProfileMenuItem> = [
  {
    type: 'Action',
    text_en: 'Logout',
    icon: 'fa fa-power-off',
    action: () => redirectToLogin()
  }
]

/**
 * Array of menu items for the main navigation.
 */
export const menuItems: Array<MenuItem> = [
  {
    item_type: 'Link',
    title_en: 'Job Runs',
    tooltip_en: 'Jobs Runs list dashboard',
    icon: 'fal fa-briefcase',
    route: '/jobs',
    view_groups: 'Everyone'
  },
  {
    item_type: 'Link',
    title_en: 'Available Jobs',
    tooltip_en: 'Available Jobs list dashboard',
    icon: 'fa-solid fa-list-check',
    route: '/available-jobs',
    view_groups: 'Everyone'
  }
]
