import React, { SetStateAction, useCallback, useEffect, useState } from 'react'
import StatisticCard from '@container/jobs/StatisticCard'

import { ReactComponent as ScheduledIcon } from '@assets/icons/schedule.svg'
import { ReactComponent as PendingIcon } from '@assets/icons/pending.svg'
import { ReactComponent as FlightIcon } from '@assets/icons/flight.svg'
import { ReactComponent as LandedIcon } from '@assets/icons/landed.svg'
import { ReactComponent as HistoricalIcon } from '@assets/icons/historical.svg'

import style from './index.module.scss'
import { cronJobService } from '@services/index'

interface JobStatistic {
  title: string
  value: number
  Icon: React.ComponentType
  leftSidebarColor: string
  backgroundColor: string
  iconBackgroundColor: string
  description: string
  key: IJob.Status
}

export const initialJobStatistics: JobStatistic[] = [
  {
    title: 'Scheduled',
    key: 'Scheduled',
    value: 0,
    Icon: ScheduledIcon,
    leftSidebarColor: '#7D99C0',
    backgroundColor: '#F5F8FF',
    iconBackgroundColor: '#F5F8FF',
    description: 'These are jobs that have some future schedule.'
  },
  {
    title: 'Pending',
    key: 'Pending',
    value: 0,
    Icon: PendingIcon,
    leftSidebarColor: '#FCA744',
    backgroundColor: 'white',
    iconBackgroundColor: '#F4EEE5',
    description: 'These are jobs that need to run within the next 12 hours.'
  },
  {
    title: 'In-Flight',
    key: 'InFlight',
    value: 0,
    Icon: LandedIcon,
    leftSidebarColor: '#43A24B',
    backgroundColor: 'white',
    iconBackgroundColor: '#E5F4F0',
    description: 'These are jobs that are currently flying or executuing.'
  },
  {
    title: 'Landed',
    key: 'Landed',
    value: 0,
    Icon: FlightIcon,
    leftSidebarColor: '#409BA6',
    backgroundColor: 'white',
    iconBackgroundColor: '#EAF6F8',
    description: 'These are jobs that have recently completed within the last 12 hours.'
  },
  {
    title: 'Historical',
    key: 'Historical',
    value: 0,
    Icon: HistoricalIcon,
    leftSidebarColor: '#334C5B',
    backgroundColor: 'white',
    iconBackgroundColor: '#F2F5F6',
    description: 'These are jobs that have already happened.'
  }
]

interface IJobStatistic {
  jobStatus: IJob.Status
  setJobStatus: React.Dispatch<SetStateAction<IJob.Status>>
}

const JobStatistic = ({ setJobStatus, jobStatus }: IJobStatistic) => {
  const [jobStatistics, setJobStatistics] = useState<JobStatistic[]>(initialJobStatistics)
  const [status, setStatus] = useState<IJob.Status>(jobStatus)

  useEffect(() => {
    const fetchJobsForStats = async () => {
      try {
        const res: any = await cronJobService.getCronJobsCount()
        if (!res.is_error) {
          let _jobStatistics = [...jobStatistics]
          _jobStatistics = _jobStatistics.map((item) => {
            return {
              ...item,
              value: getStatusValue(item.key, res.data)
            }
          })
          setJobStatistics(_jobStatistics)
        }
      } catch (error) {
        console.error('Error fetching jobs:', error)
      }
    }

    fetchJobsForStats()
  }, [])

  const getStatusValue = useCallback((key: IJob.Status, data: any) => {
    let value = 0
    switch (key) {
      case 'Scheduled':
        value = data.scheduledCount
        break
      case 'Pending':
        value = data.pendingCount
        break
      case 'Historical':
        value = data.historicalCount
        break
      case 'InFlight':
        value = data.inFlightCount
        break
      case 'Landed':
        value = data.landedCount
        break
      default:
        break
    }
    return value
  }, [])

  return (
    <div style={{ height: '100%' }}>
      <div className='d-flex align-items-center justify-content-between'>
        <span className={style.jobTitle}>Jobs</span>
      </div>
      <div className={`row gx-2 mt-2 ${style.rowContainer}`}>
        {jobStatistics.map((item, index) => (
          <div key={index} className='col-12 col-sm-12 col-md-6 colLgCustom mb-1'>
            <StatisticCard
              item={item}
              handleClick={() => {
                setJobStatus(item.key)
                setStatus(item.key)
              }}
              jobStatus={status}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
export default JobStatistic
