/* eslint-disable camelcase */
import React, { useMemo } from 'react'
import { Spinner, Button, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormikInput from '@components/Formik/FormikInput/formikInput'
import FormikSelect from '@components/Formik/formikSelect'
import {
  clientService,
  jobPriorityService,
  jobScheduleService,
  jobService,
  jobTypeService,
  runnerService
} from '@services/index'
import TimePicker from 'react-time-picker'
import style from './job.module.scss'
import 'react-time-picker/dist/TimePicker.css'
import 'react-clock/dist/Clock.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import DateTimePicker from 'react-datetime-picker'

const getLabel = (type: string) => {
  let value = ''
  switch (type) {
    case 'URL_FETCH':
      value = 'URL to Fetch'
      break

    case 'SCRIPT_EXECUTION':
      value = 'Script to Run'
      break
    case 'AUTOMATION_TASK':
      value = 'Task to Run'
      break
    case 'CUSTOM_JOB':
      value = 'Custom Job URL'
      break
  }
  return value
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Job name is required'),
  type: Yup.string().required('Job type is required'),
  typeValue: Yup.string()
    .required()
    .when('type', (type, schema) => {
      const _message = getLabel(type[0])

      return schema.required(`${_message} is required`)
    }),
  scheduleType: Yup.string().required('Job schedule type is required'),
  scheduleUnit: Yup.string().when('scheduleType', (scheduleType: any, schema) => {
    return scheduleType?.[0] === 'Frequency' ? schema.required('Schedule Unit is required') : schema
  }),
  scheduleValue: Yup.string().required('Job schedule value is required'),
  runner: Yup.string().required('Job runner is required'),
  priority: Yup.string().required('Job priority is required'),
  client: Yup.string().required('Client is required')
})

const initValue: IJob.Payload = {
  name: '',
  type: '',
  scheduleType: null,
  scheduleValue: null,
  typeValue: '',
  runner: '',
  priority: '',
  scheduleUnit: '',
  client: ''
}

interface IAddEditJob {
  handleRemoveAddJob?: () => void
  fetchJobs: () => void
  job?: IJob.Payload
}

const scheduleCustomOption = [
  { value: 'days', label: 'Days' },
  { value: 'hours', label: 'hours' },
  { value: 'minutes', label: 'Minutes' }
]

const AddEditJob = ({ handleRemoveAddJob, fetchJobs, job }: IAddEditJob) => {
  const initialValues = useMemo(() => {
    let _values = initValue
    if (job) {
      _values = {
        name: job.name,
        type: job.type,
        scheduleType: job.scheduleType,
        scheduleValue: job.scheduleValue,
        runner: job.runner,
        priority: job.priority,
        client: job.client,
        handlerId: job.handlerId,
        typeValue: job.typeValue
      }
    }
    return _values
  }, [job])

  const onSubmit = async (values: IJob.Payload) => {
    const _values = { ...values }
    _values.scheduleType?.toLocaleLowerCase() !== 'frequency' && delete _values.scheduleUnit
    if (_values.scheduleType === 'Daily') {
      const now = new Date()
      const time = _values.scheduleValue?.split(':')
      const currentYear = now.getUTCFullYear()
      const currentMonth = now.getUTCMonth()
      const currentDay = now.getUTCDate()

      const todayAt1AMUTC = new Date(
        Date.UTC(currentYear, currentMonth, currentDay, parseInt(time?.[0] ?? '0'), parseInt(time?.[1] ?? '0'), 0, 0)
      )
      const milliseconds = todayAt1AMUTC.getTime()

      _values.scheduleValue = milliseconds as any
    } else if (_values.scheduleType === 'Once') {
      const date = new Date(_values.scheduleValue ?? '')
      _values.scheduleValue = date.getTime() as any
    }

    _values.scheduleType = _values.scheduleType?.toLocaleLowerCase() ?? ''

    let res: any
    if (job) {
      res = await jobService.updateJob(job.handlerId ?? '', _values as any)
    } else {
      res = await jobService.createJob(_values as any)
    }

    if (!res.is_error) {
      handleRemoveAddJob?.()
      toast.success(`Job ${job ? 'updated' : 'created'} successfully!`)
      fetchJobs()
      return
    } else {
      toast.error('Something went wrong!')
    }
    if (res.Message) {
      toast.error(res.Message)
    }
  }

  const tomorrowDate = useMemo(() => {
    const currentDate = new Date()
    const nextDate = new Date(currentDate)
    nextDate.setDate(currentDate.getDate() + 1)
    return nextDate
  }, [])

  return (
    <>
      <div className={`p-2 ${style.formContainer}`}>
        <div className='d-flex justify-content-end'>
          <Button onClick={handleRemoveAddJob} type='button' color='edit' className='btn-sm'>
            <i className='fa-solid fa-arrow-left me-2 ' />
            Go back to job list
          </Button>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            resetForm,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            isSubmitting,
            dirty,
            values,
            touched,
            errors
          }) => {
            const handleCancel = () => {
              resetForm()
            }

            const handleTime = (value: any) => {
              setFieldTouched('scheduleValue', true)
              setFieldValue('scheduleValue', value)
            }

            const gettypeValuePlaceHolder = () => {
              let value = ''
              switch (values.type) {
                case 'URL_FETCH':
                  value = 'Type in url here'
                  break

                case 'SCRIPT_EXECUTION':
                  value = 'Type script url here'
                  break
                case 'AUTOMATION_TASK':
                  value = 'Type task url'
                  break
                case 'CUSTOM_JOB':
                  value = 'Type job url here'
                  break
              }
              return value
            }

            return (
              <>
                <form onSubmit={handleSubmit}>
                  <FormikInput placeholder='Job name' label='Job name' name='name' />

                  <FormikSelect
                    name='type'
                    placeholder='Select job type'
                    label='Select job type'
                    className='mb-2 mt-2'
                    valueKey='type'
                    labelKey='type'
                    isOptionLoadFromAPI
                    action={() => jobTypeService.getAllJobTypes()}
                  />

                  {values.type && (
                    <div className='mb-2'>
                      <FormikInput
                        placeholder={gettypeValuePlaceHolder()}
                        label={getLabel(values.type)}
                        name='typeValue'
                      />
                    </div>
                  )}

                  <FormikSelect
                    name='scheduleType'
                    placeholder='Select job schedule'
                    label='Select job schedule'
                    className='mb-2'
                    valueKey='type'
                    labelKey='type'
                    isOptionLoadFromAPI
                    getOptionLabel={(label) => (label === 'Frequency' ? 'Custom Frequency' : label)}
                    handleChange={() => {
                      setFieldValue('scheduleValue', null)
                      setFieldValue('scheduleUnit', '')
                    }}
                    action={() => jobScheduleService.getAllJobSchedules()}
                  />

                  {values.scheduleType === 'Daily' ? (
                    <div className='d-flex flex-column mb-2'>
                      <label className='form-label'>Schedule value (Select time in UTC timezone)</label>
                      <TimePicker
                        onChange={handleTime}
                        value={values.scheduleValue?.toString() ?? null}
                        className='form-control'
                        format='h:mm a'
                        disableClock
                        clearIcon={null}
                      />
                      {touched.scheduleValue && errors.scheduleValue && (
                        <span className='is-invalid'>{errors.scheduleValue}</span>
                      )}
                    </div>
                  ) : values.scheduleType === 'Once' ? (
                    <div className='d-flex flex-column mb-2'>
                      <label className='form-label'>Schedule value (Select date and time UTC timezone)</label>
                      <DateTimePicker
                        onChange={handleTime}
                        value={values.scheduleValue}
                        className='form-control'
                        format='MM/dd/yyyy h:mm aa'
                        disableClock
                        clearIcon={null}
                        minDate={tomorrowDate}
                      />

                      {touched.scheduleValue && errors.scheduleValue && (
                        <span className='is-invalid'>{errors.scheduleValue}</span>
                      )}
                    </div>
                  ) : values.scheduleType === 'Frequency' ? (
                    <div>
                      <label className='form-label'>Every</label>
                      <div className='d-flex gap-1'>
                        <FormikInput
                          errorClassName='d-none'
                          placeholder='Schudule value'
                          type='number'
                          label=''
                          name='scheduleValue'
                        />
                        <FormikSelect name='scheduleUnit' options={scheduleCustomOption} />
                      </div>
                    </div>
                  ) : null}

                  <FormikSelect
                    name='runner'
                    placeholder='Select job runner'
                    label='Select job runner'
                    className='mb-2 '
                    valueKey='name'
                    labelKey='name'
                    isOptionLoadFromAPI
                    action={() => runnerService.getAllRunners()}
                  />

                  <FormikSelect
                    name='priority'
                    placeholder='Select job priority'
                    label='Select job priority'
                    className='mb-2'
                    valueKey='level'
                    labelKey='level'
                    isOptionLoadFromAPI
                    action={() => jobPriorityService.getAllJobPriorities()}
                  />

                  <FormikSelect
                    name='client'
                    placeholder='Select client'
                    label='Select client'
                    className='mb-2'
                    valueKey='name'
                    labelKey='name'
                    isOptionLoadFromAPI
                    action={() => clientService.getAllClients()}
                  />
                  <Row className='mb-2 mt-3'>
                    <Col className='d-flex gap-2'>
                      <Button type='submit' color='edit' className='btn-sm' disabled={!dirty}>
                        <i className='fal fa-edit me-2' />
                        {job ? 'Update' : 'Submit'}
                        {isSubmitting && <Spinner className='spinner' />}
                      </Button>
                      <Button type='button' color='cancel' className='btn-sm' onClick={handleCancel} disabled={!dirty}>
                        <i className='fa fa-times me-2' />
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default AddEditJob
